import React from 'react'
import { useMaxScore } from '../../../../hooks/feature/form/useMaxScore'
import clsx from 'clsx'

const MaxScoreQuestion = () => {
  const { sumTotal, maxScore } = useMaxScore()

  return (
    <div className="lg:absolute right-10 top-0 lg:w-1/3 mb-2">
      <p className={clsx(sumTotal > maxScore && 'text-red-700')}>
        <span className="font-bold">Puntaje usado: </span>
        {sumTotal} de {maxScore}
      </p>
      {sumTotal > maxScore && (
        <p className="text-red-700 text-sm">
          Superaste el puntaje total máximo. Corrige la puntuación para continuar con la creación de tu formulario
        </p>
      )}
    </div>
  )
}

export default MaxScoreQuestion

MaxScoreQuestion.propTypes = {}
