import PropTypes from 'prop-types'
import React from 'react'
import queryString from 'query-string'
import { Button } from '../../atoms/Button'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'
import TagState from './TagState'
import ExpirationDaysText from './ExpirationDaysText'
import useFieldValue from '../../../hooks/useFieldValue'
import useSearchParams from '../../../hooks/useSearchParams'
import { getObjectFromUrlValues } from '../../../utils/helpersAdmin'

const ProceduresDetailsCards = ({ procedures = [], id, hasAccess }) => {
  const accessId = useFieldValue('access_id')
  const urlParams = useSearchParams()
  const extractedParams = getObjectFromUrlValues(['user_type_id', 'area_id', 'branch_office_id'], urlParams)
  const buttonParams = hasAccess ? { access_id: accessId } : extractedParams

  return procedures.map((answer, index) => {
    return (
      <div key={index} className="flex justify-between bg-white mb-2 border-2" data-testid="detail-card">
        <div className="md:flex justify-between w-full p-4">
          <div className="mb-1">
            <p className="font-bold mb-1">{answer.name}</p>
            {answer.document_number && (
              <BoldLabelAndContent
                el="p"
                label={`${answer.document_name}:`}
                content={answer.document_number}
                className="mb-1"
              />
            )}
            <BoldLabelAndContent el="p" label="Código:" content={answer.code} className="mb-1" />
            {answer.information_detail && (
              <div className="overflow-ellipsis mb-4 md:mb-0">
                <BoldLabelAndContent
                  el="p"
                  label="Detalle de información: "
                  content={answer.information_detail}
                  className="mb-0"
                />
              </div>
            )}
          </div>
          <div className="md:text-right md:ml-5">
            {Object.values(answer.state).map((state, index) => (
              <TagState state={state} key={index} className="mr-2 md:ml-2 md:mr-0" />
            ))}
            <BoldLabelAndContent
              el="p"
              label="Fecha de ingreso: "
              content={answer.created_at}
              className="my-1 w-max md:ml-auto max-w-full"
              contentClassName="inline-block"
            />
            {answer.observation_corrected_at && (
              <BoldLabelAndContent
                el="p"
                label="Fecha de subsanación: "
                content={answer.observation_corrected_at}
                className="my-1 w-max md:ml-auto max-w-full"
                contentClassName="inline-block"
              />
            )}
            {answer.expiration && (
              <ExpirationDaysText expiration={answer.expiration} className="my-1 w-max md:ml-auto max-w-full" />
            )}
            {answer.is_attention_time_stopped && (
              <BoldLabelAndContent
                el="p"
                label="Vence en: "
                content="esperando subsanación"
                className="my-1 w-max md:ml-auto max-w-full"
                contentClassName="inline-block"
              />
            )}
            {answer.attended_at && (
              <BoldLabelAndContent
                el="p"
                label="Fecha atendida: "
                content={answer.attended_at}
                className="my-1 w-max md:ml-auto max-w-full"
                contentClassName="inline-block"
              />
            )}
            {answer.archived_at && (
              <BoldLabelAndContent
                el="p"
                label="Fecha archivada: "
                content={answer.archived_at}
                className="my-1 w-max md:ml-auto max-w-full"
                contentClassName="inline-block"
              />
            )}
          </div>
        </div>
        <div className="flex items-center bg-indigo-400 min-w-29px">
          <Button
            variant="link"
            el="a"
            className="px-0 relative h-full max-w-29px"
            size="full"
            classes={{ root: 'min-w-0' }}
            href={`/admin/gestiones-de-tramites-habilitadas/${id}/solicitudes/${answer.id}?${queryString.stringify(
              buttonParams
            )}`}
            aria-label={`Ver detalle de solicitud de ${answer.name}`}
          >
            <KeyboardArrowRight className="w-7 text-black" />
          </Button>
        </div>
      </div>
    )
  })
}
export default ProceduresDetailsCards

ProceduresDetailsCards.propTypes = {
  id: PropTypes.string,
  procedures: PropTypes.array,
  hasAccess: PropTypes.bool
}
