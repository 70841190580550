import React from 'react'
import axios from 'axios'
import Exports from '../../../templates/Exports'
import queryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

const fetchEnabledProcedureManagement = id =>
  axios.get(`/admin/v1/enabled_procedure_managements/${id}.json`).then(a => a.data)
const fetchExports = (search, exportableId) => {
  const parsedSearch = queryString.parse(search)

  const url = queryString.stringifyUrl({
    url: `/admin/v1/exports.json?exportable_id=${exportableId}&exportable_type=procedures`,
    query: parsedSearch
  })
  return axios.get(url).then(a => a.data)
}
const filterLabel = {
  search: 'Nombre o ID',
  area: 'Area',
  state: 'Estado',
  created_at_from: 'Ingresado desde',
  created_at_to: 'Ingresado hasta',
  expires_from: 'Vence desde',
  expires_to: 'Vence hasta'
}
const ProceduresExport = () => {
  const { id } = useParams()
  const { search } = useLocation()
  const { data: formData } = useQuery(['enabledProcedureManagement', id], () => fetchEnabledProcedureManagement(id))
  const breadCrumbData = { ...formData, queryParams: search }

  const exportDescription = `Estos son los archivos exportados para ${formData?.name}`
  return (
    <Exports
      generalData={breadCrumbData}
      fetchExports={fetchExports}
      exportDescription={exportDescription}
      exportableId={id}
      filterLabel={filterLabel}
    />
  )
}

export default ProceduresExport
