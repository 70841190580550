import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageNavigation } from '../../organisms/navigation/PageNavigation'
import Container from '../../atoms/Container'
import DetailGeneralInfo from '../../organisms/procedures/DetailGeneralInfo'
import TaskHistory from '../../organisms/procedures/TaskHistory'
import useSearchParams from '../../../hooks/useSearchParams'
import useProcedure from '../../../hooks/useProcedure'
import { isSuccessful, isUnauthorized } from '../../../hooks/useQuery'
import ErrorAccessPage from '../../templates/ErrorAccessPage'
import useUserType from '../../../hooks/services/useUserType'
import useArea from '../../../hooks/useArea'
import queryString from 'query-string'

const ProceduresDetailViewer = () => {
  const { procedure_id: id, enabled_procedure_management_id: enabledProcedureManagementId } = useParams()
  const urlParams = useSearchParams()
  const userTypeId = urlParams.get('user_type_id')
  const areaId = urlParams.get('area_id')
  const branchOfficeId = urlParams.get('branch_office_id')

  const [selectedLeafId, setSelectedLeafId] = useState()
  const { findById } = useProcedure({ id })
  const { getUserType } = useUserType()
  const { getArea } = useArea()

  const {
    status,
    data: procedure,
    error
  } = findById(
    ['procedure_viewer', id, selectedLeafId, userTypeId, areaId, branchOfficeId],
    { user_type_id: userTypeId, area_id: areaId, branch_office_id: branchOfficeId },
    { refetchOnWindowFocus: true }
  )

  const { status: userTypeStatus, data: userType } = getUserType(['user_type', userTypeId])
  const { status: areaStatus, data: area } = getArea(['area', areaId])

  const queryParams = { user_type_id: userTypeId, area_id: areaId, branch_office_id: branchOfficeId }

  if (isUnauthorized(error)) {
    return (
      <ErrorAccessPage
        importantText="Lo sentimos, pero no tienes acceso para visualizar este contenido"
        description="Si deseas, puedes consultar otros contenidos desde el menú principal o regresar a la página de inicio"
        textButton="Ir a la página de inicio"
        linkButton="/formularios?action=access_error"
      />
    )
  }
  if (!isSuccessful(status) || !isSuccessful(userTypeStatus) || !isSuccessful(areaStatus)) return null

  const contactMedium = (procedure.citizen_email && 'correo') || (procedure.citizen_phone && 'SMS') || ''
  return (
    <>
      <PageNavigation
        breadcrumbData={{
          enabled_procedure_management_id: enabledProcedureManagementId,
          name: procedure.name,
          queryParams: queryString.stringify(queryParams)
        }}
      />
      <Container className="py-12">
        <p className="bg-neutral-100 py-4 px-6 text-right mb-6">
          Estás viendo la solicitud como:{' '}
          <span className="font-semibold">
            {userType.name} - {area.name}
          </span>
        </p>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="w-full md:w-5/12 lg:w-3/12">
            <DetailGeneralInfo procedure={procedure} />
          </div>
          <div className="w-full md:w-7/12 lg:w-9/12">
            <TaskHistory
              taskExecutions={procedure.task_executions}
              taskRuleExecutions={procedure.task_rule_executions}
              selectedLeafId={selectedLeafId}
              setSelectedLeafId={setSelectedLeafId}
              contactMedium={contactMedium}
            />
          </div>
        </div>
      </Container>
    </>
  )
}
export default ProceduresDetailViewer
