import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from './SelectField'
import { MenuItem } from '@material-ui/core'
import useFieldValue from '../../../hooks/useFieldValue'

const QuestionNumberField = ({
  subIndex,
  questions,
  index: questionIndex,
  parentQuestionIndex,
  fields,
  baseNameIndex,
  onChangeUniqueQuestion
}) => {
  const isSubQuestion = !!parentQuestionIndex
  const stepsAttributes = useFieldValue('steps_attributes')
  const questionList = []
  const questionListDisabled = []
  const questionIndexes = {}
  if (isSubQuestion) {
    questions.forEach((question, index) => {
      if (question._destroy !== '1') {
        questionList.push(index)
        questionIndexes[questionList.length] = index
      }
    })
  } else {
    stepsAttributes.forEach((step, index) => {
      step.questions_attributes.forEach((question, questionIndex) => {
        if (question._destroy !== '1') {
          questionList.push(index)
          questionListDisabled.push(question?.payload?.default_question)
          questionIndexes[questionList.length] = questionIndex
        }
      })
    })
  }

  const selectedIndex = isSubQuestion ? parseInt(subIndex.split('.')[1]) : parseInt(subIndex)
  const from = questionIndex

  const handleChange = event => {
    const value = event.target.value
    let to = questionIndexes[value]
    const fromStep = questionList[selectedIndex - 1]
    const toStep = questionList[value - 1]
    const activeQuestionsInStep = stepsAttributes[fromStep].questions_attributes.filter(
      question => question._destroy !== '1'
    )

    if (activeQuestionsInStep.length === 1) {
      onChangeUniqueQuestion({
        fromStep: fromStep + 1,
        toStep: toStep + 1,
        onAccept: () => {
          if (selectedIndex < value) to++
          fields.move(from, to, fromStep, toStep, isSubQuestion)
          fields.markForDestroyPage(fromStep)
        }
      })
    } else {
      if (selectedIndex < value) to++
      fields.move(from, to, fromStep, toStep, isSubQuestion)
    }
  }

  const handleSubQuestionChange = event => {
    const value = event.target.value
    let to = questionIndexes[value]
    const fromStep = ''
    const toStep = ''
    if (selectedIndex < value) to++
    fields.move(from, to, fromStep, toStep, isSubQuestion, baseNameIndex)
  }

  return (
    <SelectInput
      margin="none"
      className="ml-2 min-w-25 pl-3"
      value={selectedIndex}
      onChange={isSubQuestion ? handleSubQuestionChange : handleChange}
      inputProps={{ 'aria-label': `Selecciona la ubicación` }}
    >
      {questionList.map((_, index) => {
        return (
          <MenuItem key={index} value={index + 1} disabled={questionListDisabled[index] === 'true'}>
            {isSubQuestion ? `${parentQuestionIndex}.${index + 1}` : `${index + 1}`}
          </MenuItem>
        )
      })}
    </SelectInput>
  )
}

export default QuestionNumberField

QuestionNumberField.propTypes = {
  index: PropTypes.number,
  subIndex: PropTypes.string,
  parentQuestionIndex: PropTypes.number,
  fields: PropTypes.object,
  questions: PropTypes.array,
  baseNameIndex: PropTypes.string,
  onChangeUniqueQuestion: PropTypes.func
}
