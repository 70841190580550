import useCrud from './useCrud'
import useQuery from './useQuery'
import axios from 'axios'

const fetchAreasByUserType = (userTypeId, params) =>
  axios.get(`/admin/v1/user_types/${userTypeId}/areas.json`, { params }).then(res => res.data)

const fetchAreasByBranchOffice = branchOfficeId =>
  axios.get(`/admin/v1/branch_offices/${branchOfficeId}/areas.json`).then(res => res.data)

const fetchAllAreas = params => axios.get('/admin/v1/areas/all.json', { params }).then(a => a.data)

const useArea = () => {
  const useGetAreasByUserType = (userTypedId, params, options) =>
    useQuery(['areas_by_user_type', userTypedId, params], fetchAreasByUserType, options)

  const useGetAreasByBranchOffice = branchOfficeId =>
    useQuery(['areas_by_branch_office', branchOfficeId], fetchAreasByBranchOffice)

  const useGetAllAreas = (branchOfficeId, institutionId) =>
    useQuery(['all_areas', branchOfficeId, institutionId], () =>
      fetchAllAreas({ branch_office_id: branchOfficeId, institution_id: institutionId })
    )

  const { findById: getArea } = useCrud('/admin/v1/areas')

  return { useGetAreasByUserType, useGetAreasByBranchOffice, useGetAllAreas, getArea }
}
export default useArea
