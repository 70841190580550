import QuestionType from '../components/organisms/question_types_admin/QuestionType'
import ShortTextQuestion from '../components/organisms/question_types_admin/ShortTextQuestion'
import ParagraphQuestion from '../components/organisms/question_types_admin/ParagraphQuestion'
import DropdownQuestion from '../components/organisms/question_types_admin/DropdownQuestion'
import RadioQuestion from '../components/organisms/question_types_admin/RadioQuestion'
import CheckboxesQuestion from '../components/organisms/question_types_admin/CheckboxesQuestion'
import DateQuestion from '../components/organisms/question_types_admin/DateQuestion'
import EmailQuestion from '../components/organisms/question_types_admin/EmailQuestion'
import PhoneQuestion from '../components/organisms/question_types_admin/PhoneQuestion'
import DocTypeQuestion from '../components/organisms/question_types_admin/DocTypeQuestion'
import UbigeoQuestion from '../components/organisms/question_types_admin/UbigeoQuestion'
import HourQuestion from '../components/organisms/question_types_admin/HourQuestion'
import FileQuestion from '../components/organisms/question_types_admin/FileQuestion'
import SubtitleQuestion from '../components/organisms/question_types_admin/SubtitleQuestion'
import * as uuid from 'uuid'
import { isLast } from '../utils/destroyableFields'
import CountryQuestion from '../components/organisms/question_types_admin/CountryQuestion'
import ExternalInstitutionQuestion from '../components/organisms/question_types_admin/ExternalInstitutionQuestion'
import ExternalInstitutionTaskQuestion from '../components/organisms/question_types_admin/ExternalInstitutionTaskQuestion'
import ReferToBranchOfficeQuestion from '../components/organisms/question_types_admin/ReferToBranchOfficeQuestion'
import FullNameApplicantQuestion from '../components/organisms/question_types_admin/FullNameApplicantQuestion'
import EmailPhoneApplicantQuestion from '../components/organisms/question_types_admin/EmailPhoneApplicantQuestion'
import DocTypeApplicantQuestion from '../components/organisms/question_types_admin/DocTypeApplicantQuestion'
import getByPath from 'lodash.get'
import FullNameQuestion from '../components/organisms/question_types_admin/FullNameQuestion'
import AddressQuestion from '../components/organisms/question_types_admin/AddressQuestion'
import RadioDoubleQuestion from '../components/organisms/question_types_admin/RadioDoubleQuestion'
import CheckboxDoubleQuestion from '../components/organisms/question_types_admin/CheckboxDoubleQuestion'
import AssessmentRadioQuestion from '../components/organisms/question_types_admin/AssessmentRadioQuestion'
import AssessmentCheckboxesQuestion from '../components/organisms/question_types_admin/AssessmentCheckboxesQuestion'
import AssessmentDropdownQuestion from '../components/organisms/question_types_admin/AssessmentDropdownQuestion'

const availableQuestionTypes = {
  short_text: ShortTextQuestion,
  paragraph: ParagraphQuestion,
  dropdown: DropdownQuestion,
  radio: RadioQuestion,
  checkboxes: CheckboxesQuestion,
  radio_double: RadioDoubleQuestion,
  checkboxes_double: CheckboxDoubleQuestion,
  date: DateQuestion,
  email: EmailQuestion,
  phone: PhoneQuestion,
  doc_id_type: DocTypeQuestion,
  full_name: FullNameQuestion,
  ubigeo: UbigeoQuestion,
  address: AddressQuestion,
  hour: HourQuestion,
  file: FileQuestion,
  subtitle: SubtitleQuestion,
  country: CountryQuestion,
  external_institution: ExternalInstitutionQuestion,
  external_institution_task: ExternalInstitutionTaskQuestion,
  refer_branch_office: ReferToBranchOfficeQuestion,
  full_name_applicant: FullNameApplicantQuestion,
  email_phone_applicant: EmailPhoneApplicantQuestion,
  doc_id_type_applicant: DocTypeApplicantQuestion,
  assessment_radio: AssessmentRadioQuestion,
  assessment_checkboxes: AssessmentCheckboxesQuestion,
  assessment_dropdown: AssessmentDropdownQuestion
}

const getQuestionTypeObject = kind => (kind ? { ...QuestionType, ...availableQuestionTypes[kind] } : null)

const questionOptions = (stepsValue, key, baseName, isSubQuestion, formType = 'form') => {
  const options = []
  const enabledApplicantQuestionTypes = getEnabledQuestionTypes(
    stepsValue,
    key,
    baseName,
    isSubQuestion,
    formType,
    'applicant'
  )
  if (enabledApplicantQuestionTypes.length) {
    addOptionsGroup(options, 'Información del solicitante', enabledApplicantQuestionTypes)
  }
  addOptionsGroup(options, 'Preguntas generales', getQuestionTypeBy(formType, 'general'))
  addOptionsGroup(options, 'Otros', getQuestionTypeBy(formType, 'other'))
  const specialQuestionTypes = getEnabledQuestionTypes(
    stepsValue,
    key,
    baseName,
    isSubQuestion,
    formType,
    'specialTasks'
  )
  if (specialQuestionTypes.length > 0) {
    addOptionsGroup(options, 'Tareas especiales', specialQuestionTypes)
  }
  return options
}

const getEnabledQuestionTypes = (stepsValue, key, baseName, isSubQuestion, formType, formGroup) => {
  const selectedQuestionTypes = getSelectedKinds(stepsValue, key, baseName, isSubQuestion)
  return getQuestionTypeBy(formType, formGroup).filter(questionType => !selectedQuestionTypes.includes(questionType))
}

const addOptionsGroup = (options, label, questionTypes) => {
  options.push({ groupLabel: label })
  questionTypes.map(questionType => options.push(getQuestionTypeObject(questionType).selectOption))
}

const getQuestionTypeBy = (formType, group) =>
  questionTypeCodes[group].filter(questionType => questionTypeByFormTypes[formType].includes(questionType))

const getSelectedKinds = (stepsValue, key, baseName, isSubQuestion) => {
  let questions = getQuestionsPreviousSteps(stepsValue, stepsValue.length)
  if (isSubQuestion) {
    questions = questions.concat(getByPath(stepsValue, baseName.replace('steps_attributes', '')))
  }
  const selectedKinds = []
  questions.forEach(question => {
    if (!question._destroy && question.key !== key && question.kind) {
      selectedKinds.push(question.kind)
    }
    if (!isSubQuestion && question.has_sub_questions) {
      for (const key in question.children_attributes) {
        question.children_attributes[key].forEach(subQuestion => {
          if (!subQuestion._destroy && subQuestion.key !== key && subQuestion.kind) {
            selectedKinds.push(subQuestion.kind)
          }
        })
      }
    }
  })
  return selectedKinds
}

const questionTypeCodes = {
  applicant: ['full_name_applicant', 'email_phone_applicant', 'doc_id_type_applicant'],
  general: [
    'short_text',
    'paragraph',
    'dropdown',
    'radio',
    'checkboxes',
    'assessment_dropdown',
    'assessment_radio',
    'assessment_checkboxes',
    'radio_double',
    'checkboxes_double',
    'date',
    'full_name',
    'email',
    'phone',
    'doc_id_type',
    'ubigeo',
    'address',
    'hour',
    'file',
    'country',
    'external_institution'
  ],
  other: ['subtitle'],
  specialTasks: ['external_institution_task', 'refer_branch_office']
}

const defaultGeneralList = [
  'short_text',
  'paragraph',
  'dropdown',
  'radio',
  'checkboxes',
  'radio_double',
  'checkboxes_double',
  'date',
  'full_name',
  'email',
  'phone',
  'doc_id_type',
  'ubigeo',
  'address',
  'hour',
  'file'
]

const questionTypeByFormTypes = {
  form: [
    ...questionTypeCodes.applicant,
    'short_text',
    'paragraph',
    'dropdown',
    'radio',
    'checkboxes',
    'radio_double',
    'checkboxes_double',
    'date',
    'full_name',
    'email',
    'phone',
    'doc_id_type',
    'ubigeo',
    'address',
    'hour',
    'file',
    ...questionTypeCodes.other
  ],
  procedure_management: [
    ...questionTypeCodes.applicant,
    'short_text',
    'paragraph',
    'dropdown',
    'radio',
    'checkboxes',
    'radio_double',
    'checkboxes_double',
    'date',
    'full_name',
    'email',
    'phone',
    'doc_id_type',
    'ubigeo',
    'address',
    'hour',
    'file',
    'country',
    ...questionTypeCodes.other
  ],
  enabled_procedure_management: [
    ...questionTypeCodes.applicant,
    'short_text',
    'paragraph',
    'dropdown',
    'radio',
    'checkboxes',
    'radio_double',
    'checkboxes_double',
    'date',
    'full_name',
    'email',
    'phone',
    'doc_id_type',
    'ubigeo',
    'address',
    'hour',
    'file',
    'country',
    ...questionTypeCodes.other
  ],
  defaultTask: [...defaultGeneralList, 'country', 'external_institution', ...questionTypeCodes.other],
  referBranchOfficeTask: [
    ...defaultGeneralList,
    'country',
    'external_institution',
    'refer_branch_office',
    ...questionTypeCodes.other
  ],
  referInstitutionTask: [
    ...defaultGeneralList,
    'country',
    'external_institution',
    'external_institution_task',
    ...questionTypeCodes.other
  ],
  assessment: ['assessment_dropdown', 'assessment_radio', 'assessment_checkboxes', ...questionTypeCodes.other]
}
const questionTypesGroupInclude = (group, kind) => questionTypeCodes[group].includes(kind)

const getIndexSubtype = ({ questionsPreviousSteps = [], questions, index, parentQuestionIndex }) => {
  const subtype = getSubtype(questions[index].kind)
  const allQuestions = questionsPreviousSteps.concat(questions.slice(0, index + 1))
  const indexSubtype = allQuestions.reduce(
    (n, question) => n + (getSubtype(question.kind) === subtype && !question._destroy),
    0
  )
  return parentQuestionIndex ? `${parentQuestionIndex}.${indexSubtype}` : indexSubtype
}

const showRequiredSwitch = (kind, typeForm) =>
  kind && getSubtype(kind) === 'Pregunta' && !requiredDefault(kind, typeForm)

const showManualRequestSwitch = (kind, typeForm) =>
  kind &&
  getSubtype(kind) === 'Pregunta' &&
  ['radio', 'dropdown', 'checkboxes'].includes(kind) &&
  typeForm === 'procedure_management'

const requiredDefault = (kind, typeForm) => {
  const requiredQuestionsByForm = {
    procedure_management: ['full_name_applicant'],
    enabled_procedure_management: ['full_name_applicant'],
    referBranchOfficeTask: ['refer_branch_office'],
    referInstitutionTask: ['external_institution_task']
  }
  return requiredQuestionsByForm[typeForm]?.includes(kind)
}

const requiredToList = {
  external_institution_task: 'el funcionario',
  refer_branch_office: 'el funcionario'
}

const requiredToLabel = kind => requiredToList[kind] || 'el ciudadano'

const canBeCopied = (kind, typeForm) => !requiredDefault(kind, typeForm) && !questionTypeCodes.applicant.includes(kind)

const canBeDeleted = (kind, typeForm) => !requiredDefault(kind, typeForm)

const showSplitPage = (questions, index, parentQuestionIndex) =>
  !isLast(questions, index) && !parentQuestionIndex && getSubtype(questions[index]?.kind) === 'Pregunta'

const showConditionalQuestionsSwitch = (kind, parentQuestionIndex) =>
  !parentQuestionIndex && ['radio', 'dropdown'].includes(kind)

const showDisableFutureDateSwitch = kind => kind === 'date'

const showShortTextLimit = kind => kind === 'short_text'

const getSubtype = kind => getQuestionTypeObject(kind)?.subtype || 'Pregunta'

const getQuestionsPreviousSteps = (stepsValue, currentIndex) =>
  stepsValue
    .slice(0, currentIndex)
    .reduce(
      (questions, step) =>
        questions.concat((!step._destroy && step.questions_attributes.filter(question => !question._destroy)) || []),
      []
    )

const cloneQuestion = question => {
  const newQuestion = { ...question, id: null, key: uuid.v4() }
  if (question.children_attributes) {
    newQuestion.children_attributes = {}
    for (const key in question.children_attributes) {
      newQuestion.children_attributes[key] = question.children_attributes[key].map(ch => ({
        ...ch,
        id: null,
        key: uuid.v4()
      }))
    }
  }
  return newQuestion
}

const getFirstQuestionByType = (stepsValue, type) => {
  let firstQuestion = { question: null, questionIndex: null, stepIndex: null }
  stepsValue.forEach((step, stepIndex) => {
    step.questions_attributes.forEach((question, questionIndex) => {
      if (!firstQuestion.question && question.kind === type && !question?._destroy) {
        firstQuestion = { question, questionIndex, stepIndex }
      }
    })
  })
  return firstQuestion
}

const getFirstEmailQuestion = values => {
  const { question: firstEmailQuestion } = getFirstQuestionByType(values.steps_attributes, 'email')
  const { question: firstEmailPhoneApplicantQuestion } = getFirstQuestionByType(
    values.steps_attributes,
    'email_phone_applicant'
  )
  return firstEmailPhoneApplicantQuestion || firstEmailQuestion
}

const getSubListEmailQuestion = values => {
  const subQuestionsList = values.steps_attributes.reduce((prev, current) => {
    const listQuestion = []
    current.questions_attributes.forEach(question => {
      if (['dropdown', 'radio'].includes(question.kind) && question.has_sub_questions && question._destroy !== '1') {
        listQuestion.push(question)
      }
    })
    return [...prev, ...listQuestion]
  }, [])

  let questionsWithEmail = []
  subQuestionsList.forEach(question => {
    if (question?.children_attributes) {
      const childrenList = Object.values(question.children_attributes)
      const hasEmails = childrenList.every(children =>
        children.some(el => ['email_phone_applicant', 'email'].includes(el.kind) && el._destroy !== '1')
      )

      if (hasEmails) {
        questionsWithEmail = childrenList.map(children =>
          children.find(el => ['email_phone_applicant', 'email'].includes(el.kind) && el._destroy !== '1')
        )
      }
    }
  })

  return questionsWithEmail
}

const hasAutoResponse = values => values.auto_response === 'yes' || values.auto_response === true

const hasPaymentConfirm = values => values.it_costs === 'yes' || values.it_costs === true

const isFirstQuestionByType = (stepsValue, type, questionIndex, stepIndex) => {
  const { questionIndex: indexFirstEmail, stepIndex: stepIndexFirstEmail } = getFirstQuestionByType(stepsValue, type)
  return questionIndex === indexFirstEmail && stepIndex === stepIndexFirstEmail
}

const isSubtitleFromTemplate = question => createdFromTemplate(question) && question.kind === 'subtitle'

const createdFromTemplate = row => row?.template_question_id || row?.template_step_id

const showEditableAlternativesSwitch = (kind, editableAlternatives) =>
  editableAlternatives && ['radio', 'dropdown', 'checkboxes', 'file'].includes(kind)

const getLabel = question =>
  ['doc_id_type', 'doc_id_type_applicant'].indexOf(question.kind) === -1
    ? question.payload?.label
    : question.payload?.doc_id_label

const getLabelByKind = kind => getQuestionTypeObject(kind).selectOption.label

const getSpecialTaskType = kind => {
  return {
    refer_branch_office_task: 'referBranchOfficeTask',
    refer_to_institution: 'referInstitutionTask'
  }[kind]
}

const showOneRowRequired = kind => ['checkboxes_double', 'radio_double'].includes(kind)

const showOneMaxColRequired = kind => ['checkboxes_double', 'radio_double'].includes(kind)

const isAssessmentQuestion = kind => ['assessment_radio', 'assessment_checkboxes', 'assessment_dropdown'].includes(kind)

const isConfirmationQuestion = (kind, typeForm) =>
  kind === 'email_phone_applicant' && typeForm === 'procedure_management'

export {
  questionOptions,
  getIndexSubtype,
  requiredToLabel,
  showRequiredSwitch,
  showManualRequestSwitch,
  canBeCopied,
  canBeDeleted,
  getSubtype,
  showConditionalQuestionsSwitch,
  showDisableFutureDateSwitch,
  getQuestionsPreviousSteps,
  cloneQuestion,
  getFirstQuestionByType,
  isFirstQuestionByType,
  getQuestionTypeObject,
  getFirstEmailQuestion,
  getSubListEmailQuestion,
  hasAutoResponse,
  hasPaymentConfirm,
  isSubtitleFromTemplate,
  showSplitPage,
  showEditableAlternativesSwitch,
  createdFromTemplate,
  getLabel,
  questionTypesGroupInclude,
  questionTypeByFormTypes,
  requiredDefault,
  getEnabledQuestionTypes,
  getLabelByKind,
  getSpecialTaskType,
  showOneRowRequired,
  showOneMaxColRequired,
  showShortTextLimit,
  isAssessmentQuestion,
  isConfirmationQuestion
}
