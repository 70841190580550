import React from 'react'
import useUserPermission from '../../../../hooks/useUserPermission'
import ProceduresDetailManagement from '../../../organisms/procedures/ProceduresDetailManagement'
import ProceduresDetailViewer from '../../../organisms/procedures/ProceduresDetailViewer'

const ProceduresDetail = () => {
  const { canManageProcedures, canSeeProcedures } = useUserPermission('procedureManagement')

  if (canManageProcedures) return <ProceduresDetailManagement />

  if (canSeeProcedures) return <ProceduresDetailViewer />

  return null
}
export default ProceduresDetail
