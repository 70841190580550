import React from 'react'
import PropTypes from 'prop-types'
import TextField from '../../../molecules/fields/TextField'
import { useAssessment } from '../../../../hooks/feature/form/useAssessment'
import { useMaxScore } from '../../../../hooks/feature/form/useMaxScore'
import formValidations from '../../../../utils/formValidations'
import * as validations from '../../../../utils/validations'
import Error from '../../../molecules/Error'
import { useCustomField } from '../../../../hooks/useCustomField'
import clsx from 'clsx'
import useMediaQuery, { screenSizes } from '../../../../hooks/useMediaQuery'

const name = 'max_score'

const MaxScoreField = ({ pageNumber }) => {
  const { canStepChangeByAssessment } = useAssessment()
  const canStepChange = canStepChangeByAssessment(pageNumber)
  const { sumTotal, maxScore } = useMaxScore()
  const { error, meta: metaField } = useCustomField(name, {
    subscription: { touched: true, error: true, submitError: true, dirtySinceLastSubmit: true, submitFailed: true }
  })
  const hasErrorAfterSubmit = metaField.submitFailed && error
  const matchTabletSize = useMediaQuery(screenSizes.tablet)

  return (
    canStepChange && (
      <div className="mb-8 js-field-container">
        <div
          className={clsx(
            'shadow-sm rounded border p-6 bg-white flex justify-between items-start lg:items-center text-xl flex-col lg:flex-row',
            hasErrorAfterSubmit && 'border-2 border-solid border-red-700'
          )}
        >
          <TextField
            label={`Puntaje máximo del formulario`}
            orientation={matchTabletSize ? 'horizontal' : 'vertical'}
            name={name}
            type="number"
            min="0"
            size="sm"
            margin="none"
            rootClassName=""
            hideError
            validate={validations.mix(validations.required(), formValidations.validateMaxScore())}
          />
          <p className="mt-4 lg:mt-0">
            <span className="font-bold">Puntaje usado: </span>
            {sumTotal} de {maxScore}
          </p>
        </div>
        {hasErrorAfterSubmit && <Error name={name} className="mt-4" />}
      </div>
    )
  )
}

export default MaxScoreField

MaxScoreField.propTypes = {
  pageNumber: PropTypes.number
}
