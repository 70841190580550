import React from 'react'
import ProceduresCollaborator from '../../../organisms/enabled_procedure_managements/Procedures'
import useUserPermission from '../../../../hooks/useUserPermission'
import ProceduresReader from '../../../organisms/enabled_procedure_managements/ProceduresReader'

const Procedures = () => {
  const { canManageProcedures, canSeeProcedures } = useUserPermission('procedureManagement')

  if (canManageProcedures) return <ProceduresCollaborator />

  if (canSeeProcedures) return <ProceduresReader />

  return null
}

export default Procedures
