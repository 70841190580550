import PropTypes from 'prop-types'
import React from 'react'
import * as validations from '../../../utils/validations'
import formValidations from '../../../utils/formValidations'
import TextField from '../../molecules/fields/TextField'
import QuestionOptionsSection from './QuestionOptionsSection'
import AssessmentAlternativesField from './AssessmentAlternativesField'
import ScoreType from '../forms/assessment/ScoreType'
import useFieldValue from '../../../hooks/useFieldValue'

const AssessmentRadioField = ({ baseName, label, validate }) => {
  const scoreType = useFieldValue(`${baseName}.payload.score_type`)

  const options = [
    {
      value: 'unique_score',
      label: 'La pregunta tendrá un puntaje único.',
      additionalInformation: (
        <TextField
          ariaLabel={`Indica el puntaje único`}
          placeholder={`Indica el puntaje. Ej.: 5`}
          name={`${baseName}.payload.unique_score_value`}
          margin="minimun"
          size="md"
          rootClassName="flex-1 ml-8"
          validate={validations.required()}
          type="number"
          min="0"
        />
      )
    },
    {
      value: 'varied_score',
      label: 'La pregunta tendrá un puntaje diferenciado por respuesta.'
    },
    {
      value: 'no_score',
      label: 'Esta pregunta no tendrá puntaje.'
    }
  ]

  return (
    <>
      <TextField
        label="Etiqueta de la pregunta"
        size="2xl"
        name={`${baseName}.payload.label`}
        validate={validate}
        margin="minimun"
        errorClassName="relative inset-0"
        initialValue=""
      />
      <QuestionOptionsSection baseName={baseName} margin="normal" size="full" />
      <ScoreType baseName={baseName} options={options} validate={validations.required()} />
      <AssessmentAlternativesField
        hasAddOtherOption
        baseName={baseName}
        label={label}
        key={scoreType}
        alternativeValidations={validations.mix(
          validations.requiredArray('Ingresa al menos dos opciones', 2),
          scoreType === 'unique_score' && formValidations.minUniqueScoreSelected()
        )}
        asideAlternative={
          <>
            <legend className="font-bold flex items-center mb-1">Alternativas</legend>
            <p className="mb-2">Crea un máximo de 5 opciones de respuesta.</p>
          </>
        }
      />
    </>
  )
}

export default AssessmentRadioField

AssessmentRadioField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func
}
