const formSteps = {
  SELECTION: {
    label: 'Selección del trámite',
    title: 'Selección del trámite',
    hint: 'Elige qué trámite habilitarás para tu entidad.'
  },
  FORM: {
    label: 'Edición del formulario',
    title: 'Edición del formulario',
    hint: 'Revisa el formulario y modifica los campos editables, si lo requieres.'
  },
  AREA_AND_USERS: {
    label: 'Áreas y cuentas de usuarios',
    title: 'Áreas y cuentas de usuarios',
    hint: 'Configura o crea las áreas y cuentas involucradas en la gestión del trámite.'
  },
  PREVIEW: {
    label: 'Vista previa y publicación',
    hint: 'Revisa la gestión del trámite, publícala y comparte el enlace con los ciudadanos.'
  }
}

const pageDescription = ({
  canFilterByInstitution,
  canFilterByBranchOffice,
  canFilterAndHasNotBranchOffices,
  canSeeBranchRecords
}) => {
  if (canFilterByInstitution)
    return 'Estas son las gestiones de trámites creadas para las instituciones públicas usuarias de Facilita.'
  if (canFilterByBranchOffice) return 'Selecciona la sede cuyas gestiones de trámites habilitadas quieres ver.'
  if (canFilterAndHasNotBranchOffices) return 'Estas son las gestiones de trámites habilitadas en tu institución.'
  if (canSeeBranchRecords) return 'Estas son las gestiones de trámites habilitadas en tu sede.'
}

const orderOptions = canSeeAllEnabledProcedureManagements => {
  if (canSeeAllEnabledProcedureManagements) {
    return [
      { value: 'create_desc', label: 'Habilitación: más recientes primero' },
      { value: 'create_asc', label: 'Habilitación: más antigua primero' },
      { value: 'count_desc', label: 'Solicitudes: mayor cantidad primero' },
      { value: 'count_asc', label: 'Solicitudes: menor cantidad primero' }
    ]
  }

  return [
    { value: 'create_desc', label: 'Creación: más recientes primero' },
    { value: 'create_asc', label: 'Creación: más antiguos primero' },
    { value: 'update_desc', label: 'Actualización: más recientes primero' },
    { value: 'update_asc', label: 'Actualización: más antiguos primero' }
  ]
}

const instructions = [
  'Las preguntas en fondo gris no son editables, aparecerán tal como están en tu formulario final.',
  'Si encuentras preguntas en fondo blanco, puedes editarlas.'
]

const filterState = states =>
  states?.filter(state => state.name !== 'Atendido' && state.name !== 'Archivado').map(state => state.id.toString())

const optionsOrderBy = [
  { value: 'creation_datetime desc', label: 'Ingreso: más recientes primero' },
  { value: 'creation_datetime asc', label: 'Ingreso: más antiguos primero' },
  { value: 'deadline asc', label: 'Vencimiento: más próximos primero' },
  { value: 'deadline desc', label: 'Vencimiento: más lejanos primero' }
]

const exportUrl = (id, queryParams) => `/gestiones-de-tramites-habilitadas/${id}/exportaciones${queryParams}`

export { formSteps, orderOptions, pageDescription, instructions, filterState, optionsOrderBy, exportUrl }
