import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import ProcedureManagementsHowToCreate from '../../components/pages/admin/procedures_managements/ProcedureManagementsHowToCreate'
import ProcedureManagementsWhatIs from '../../components/pages/admin/procedures_managements/ProcedureManagementsWhatIs'
import { ProcedureManagementsNew } from '../../components/pages/admin/procedures_managements/ProcedureManagementsNew'
import { ProcedureManagementsEdit } from '../../components/pages/admin/procedures_managements/ProcedureManagementsEdit'
import ProcedureManagementsUserTypesTasksConnections from '../../components/pages/admin/procedures_managements/ProcedureManagementsUserTypesTasksConnections'
import ProcedureManagementsPreview from '../../components/pages/admin/procedures_managements/ProcedureManagementsPreview'
import EnabledProcedureManagementsSelect from '../../components/pages/admin/procedures_managements/EnabledProcedureManagementsSelect'
import EnabledProcedureManagementsNew from '../../components/pages/admin/procedures_managements/EnabledProcedureManagementsNew'
import EnabledProcedureManagementsEdit from '../../components/pages/admin/procedures_managements/EnabledProcedureManagementsEdit'
import EnabledProcedureManagementsAreasAndUsers from '../../components/pages/admin/procedures_managements/EnabledProcedureManagementsAreasAndUsers'
import EnabledProcedureManagementsPreview from '../../components/pages/admin/procedures_managements/EnabledProcedureManagementsPreview'
import Procedures from '../../components/pages/admin/procedures_managements/Procedures'
import ProceduresExport from '../../components/pages/admin/procedures_managements/ProceduresExport'
import EnabledProcedureManagementExport from '../../components/pages/admin/procedures_managements/EnabledProcedureManagementExport'
import ProceduresDetail from '../../components/pages/admin/procedures_managements/ProceduresDetail'
import ProceduresManualEntry from '../../components/pages/admin/procedures_managements/ProceduresManualEntry'
import FormEditTranslation from '../../components/pages/admin/form_translations/FormEditTranslation'
import FormNewTranslation from '../../components/pages/admin/form_translations/FormNewTranslation'
import FormTranslations from '../../components/pages/admin/form_translations/FormTranslations'
import useUserPermission from '../useUserPermission'

const useProceduresManagementsRoute = () => {
  const { canSeeProcedureManagements, canSeeEnabledProcedureManagements } = useUserPermission('menu')
  const {
    canSeeAllEnabledProcedureManagements,
    canManageEnabledProcedureManagements,
    canCreateOrEditProcedureManagements,
    canManageProcedures,
    canSeeProcedures
  } = useUserPermission('procedureManagement')

  return [
    {
      path: '/gestiones-de-tramites-habilitadas',
      component: ResourcesTab,
      hasPermission: canSeeAllEnabledProcedureManagements || canSeeEnabledProcedureManagements
    },
    { path: '/gestiones-de-tramites', component: ResourcesTab, hasPermission: canSeeProcedureManagements },
    {
      path: '/gestiones-de-tramites/como-se-crea-un-gestor-de-tramites',
      component: ProcedureManagementsHowToCreate,
      hasPermission: true
    },
    {
      path: '/gestiones-de-tramites/que-es-un-gestor-de-tramites',
      component: ProcedureManagementsWhatIs,
      hasPermission: true
    },
    {
      path: '/gestiones-de-tramites/crear',
      component: ProcedureManagementsNew,
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites/:id/editar',
      component: ProcedureManagementsEdit,
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites/:id/formulario-inicial',
      component: ProcedureManagementsEdit,
      props: { step: 2 },
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites/:id/tipos-de-usuarios-tareas-conexiones',
      component: ProcedureManagementsUserTypesTasksConnections,
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites/:id/traducciones',
      component: FormTranslations,
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites/:procedureManagementId/formulario/:id/editar-traduccion',
      component: FormEditTranslation,
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites/:procedureManagementId/formulario/:id/crear-traduccion',
      component: FormNewTranslation,
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites/:id/vista-previa',
      component: ProcedureManagementsPreview,
      hasPermission: canCreateOrEditProcedureManagements
    },
    {
      path: '/gestiones-de-tramites-habilitadas/crear',
      component: EnabledProcedureManagementsSelect,
      hasPermission: canManageEnabledProcedureManagements
    },
    {
      path: '/gestiones-de-tramites-habilitadas/crear/:procedure_management_id',
      component: EnabledProcedureManagementsNew,
      hasPermission: canManageEnabledProcedureManagements
    },
    {
      path: '/gestiones-de-tramites-habilitadas/:id/editar',
      component: EnabledProcedureManagementsEdit,
      hasPermission: canManageEnabledProcedureManagements
    },
    {
      path: '/gestiones-de-tramites-habilitadas/:id/areas-y-usuarios',
      component: EnabledProcedureManagementsAreasAndUsers,
      hasPermission: canManageEnabledProcedureManagements
    },
    {
      path: '/gestiones-de-tramites-habilitadas/:id/vista-previa',
      component: EnabledProcedureManagementsPreview,
      hasPermission: true,
      hasFeedback: true
    },
    {
      path: '/gestiones-de-tramites-habilitadas/:id/solicitudes',
      component: Procedures,
      hasPermission: canManageProcedures || canSeeProcedures
    },
    {
      path: '/gestiones-de-tramites-habilitadas/:id/exportaciones',
      component: ProceduresExport,
      hasPermission: canManageProcedures || canSeeProcedures
    },
    {
      path: '/gestiones-de-tramites-habilitadas/exportaciones',
      component: EnabledProcedureManagementExport,
      hasPermission: canSeeAllEnabledProcedureManagements
    },
    {
      path: '/gestiones-de-tramites-habilitadas/:enabled_procedure_management_id/solicitudes/:procedure_id',
      component: ProceduresDetail,
      hasPermission: canManageProcedures || canSeeProcedures
    },
    {
      path: '/gestiones-de-tramites-habilitadas/:id/entrada-manual',
      component: ProceduresManualEntry,
      hasPermission: canManageProcedures
    }
  ]
}
export default useProceduresManagementsRoute
