import { getSumScoreBySteps } from '../../../configs/formsConfig'
import useFieldValue from '../../useFieldValue'

export const useMaxScore = () => {
  const maxScore = useFieldValue('max_score')
  const stepsAttributes = useFieldValue('steps_attributes')

  const sumTotal = getSumScoreBySteps(stepsAttributes)

  return { sumTotal, maxScore }
}
