import React, { Fragment, useRef, useState } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import Container from '../../atoms/Container'
import { PageNavigation } from '../../organisms/navigation/PageNavigation'
import Title from '../../atoms/Title'
import useEnabledProcedureManagement from '../../../hooks/useEnabledProcedureManagement'
import { isSuccessful } from '../../../hooks/useQuery'
import useSearchParams from '../../../hooks/useSearchParams'
import ProcedureBody from '../../organisms/procedures/ProcedureBody'
import { filterState } from '../../../configs/enabledProcedureManagementsConfig'
import UserTypeAreaFilter from './UserTypeAreaFilter'
import AutoSaveForm from '../../molecules/AutoSaveForm'
import useFilters from '../../../hooks/useFilters'

const ProceduresReader = () => {
  const { id } = useParams()
  const history = useHistory()
  const urlParams = useSearchParams()
  const branchOfficeId = urlParams.get('branch_office_id')
  const stateIds = urlParams.getAll('state[]')
  const [areaId, setAreaId] = useState(urlParams.get('area_id'))
  const [userTypeId, setUserTypeId] = useState(urlParams.get('user_type_id'))
  const [defaultFilters, setDefaultFilters] = useState(null)
  const { onFilter, initialFilters } = useFilters(``)

  const hasRequiredParam = Boolean(areaId) && Boolean(userTypeId)
  const firstLoadStates = useRef(stateIds)

  const { findById, getStates } = useEnabledProcedureManagement(id)
  const { data: enabled, status: enabledStatus } = findById(['enabled_procedure_management', id])

  const { data: states, status: stateStatus } = getStates(
    ['states', areaId, userTypeId],
    { area_id: areaId, user_type_id: userTypeId },
    {
      enabled: hasRequiredParam,
      onSuccess: data => {
        setInitialFilter(data)
      }
    }
  )

  const handleChangeProfile = (value, filter) => {
    if (!value) setDefaultFilters(null)
    if (Object.hasOwn(filter, 'areaId')) setAreaId(filter.areaId)
    if (Object.hasOwn(filter, 'userTypeId')) setUserTypeId(filter.userTypeId)
  }

  const setInitialFilter = states => {
    const filter = {
      state: filterState(states),
      branch_office_id: branchOfficeId,
      user_type_id: userTypeId,
      area_id: areaId,
      sort: 'deadline asc'
    }

    firstLoadStates.current = null
    const path = { search: queryString.stringify(filter, { arrayFormat: 'bracket' }) }
    history.replace(path)
    setDefaultFilters(filter)
  }

  return (
    isSuccessful(enabledStatus) && (
      <Fragment>
        <PageNavigation breadcrumbData={enabled} />
        <Container>
          <div className="flex flex-col md:flex-row justify-between px-4 md:px-0 pt-16 pb-10">
            <Title aria-describedby="page-description">Gestión de {enabled.name}</Title>
          </div>
          <AutoSaveForm onSubmit={onFilter} initialValues={initialFilters}>
            <UserTypeAreaFilter
              userTypeList={enabled?.user_types_attributes}
              onChange={handleChangeProfile}
              onFilter={onFilter}
            />
          </AutoSaveForm>
          {isSuccessful(stateStatus) && defaultFilters && (
            <ProcedureBody
              defaultFilters={defaultFilters}
              id={id}
              areaId={parseInt(areaId)}
              states={states}
              stateStatus={stateStatus}
              userTypes={enabled?.user_types_attributes}
            />
          )}
        </Container>
      </Fragment>
    )
  )
}

export default ProceduresReader
